@import "../../styles";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.scroll {
  overflow-x: auto;
  display: flex;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $blue-bg;
    border-radius: 4px;
    background-color: $blue-bg;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $blue-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 40px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $blue;
    border: 4px solid $border;
  }
}

.tableWrapper {
  position: relative;
  width: 100%;
}

table.common {
  // width: max-content;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  thead {
    height: 48px;
    background-color: $blue-bg;

    &:hover .sortIcon {
      visibility: visible;
    }
  }

  th {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $gray;
    padding: 10px 16px;
    vertical-align: middle;
    border: 1px solid #d3e4ee;
    border-bottom: none;
    border-right: none;
    border-left: none;
    text-align: start;
    white-space: nowrap;

    &:first-child {
      padding-left: 28px;
      border-radius: 4px 0 0 0;
      border: 1px solid $border;
      border-bottom: none;
      border-right: none;
    }

    &:last-child {
      border-radius: 0 4px 0 0;
      border: 1px solid $border;
      border-bottom: none;
      border-left: none;
      padding-right: 26px;
    }
  }

  tr {
    width: 100%;
    padding: 20px 0;
    height: 48px;
    box-sizing: border-box;

    &:last-child td:first-child {
      border-left: 1px solid #d3e4ee;
      border-right: 0;
      border-bottom-left-radius: 4px;
    }

    &:last-child td:last-child {
      border-right: 1px solid #d3e4ee;
      border-left: none;
      border-bottom-right-radius: 4px;
    }

    &:last-of-type td {
      border-bottom: 1px solid $border;
    }
  }

  td {
    background: white;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $black;
    border: 1px solid $border;
    border-right: none;
    border-left: none;
    border-bottom: none;
    vertical-align: middle;
    padding: 0 16px;
    max-width: 300px;

    &:first-child {
      border-radius: 0 0 0 0;
      // border-top: none;
      border-left: 1px solid $border;
      padding-left: 28px;
    }

    &:last-child {
      border-radius: 0 0 0 0;
      border-right: 1px solid $border;
      // border-top: none;
      padding-right: 28px;
    }
  }
}

.noData {
  text-align: center;
  border-left: 1px solid $border !important;

  &Inner {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
    padding: 18px 0;
    font-size: 16px;
    font-weight: 600;
    color: $gray;
  }
}

.thInner {
  display: flex;
  column-gap: 10px;
}

.sortIcon {
  visibility: hidden;

  &.active {
    visibility: visible !important;
  }
}

.pointer {
  cursor: pointer;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.6;
  padding-top: 20px;
}
