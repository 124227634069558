@import "../../styles";

.ticketsTable {
  margin-top: 32px;
  background: white;
  border: 1px solid $border;
  border-radius: 4px;
}

.tabs {
  display: flex;

  & .tab:last-child {
    margin-right: 0;
  }
}

.headerBlockTabsTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 374.9px) {
    padding: 0 15px;
  }
}

.headerBlockTabs {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: $black;
  @media (max-width: 767.9px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  width: 140px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -2.5px;
  border-bottom: 3px solid transparent;
  margin-right: 5px;
  color: $gray;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767.9px) {
    width: 80px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
}

.tab.tabActive {
  color: $blue;
  border-bottom: 3px solid $blue;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.ticketTr {
  height: 56px;
  cursor: pointer;

  &:hover {
    background-color: $background;
  }
}

.ticketTd {
  padding: 0 24px;
}

.subject {
  width: 100%;
}

.date {
  & > div {
    white-space: nowrap;
  }
}

.topic {
  & > span {
    background-color: $blue;
    white-space: nowrap;
    color: $white;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 12px 4.5px 12px;
    border-radius: 4px;
  }
}