@import "src/styles/common";

.container {
  position: relative;
  width: 300px;
}
.dayPicker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: $white;
  margin: 0;
}