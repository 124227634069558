.footer {
  display: flex;
  column-gap: 20px;

  button {
    width: 100%;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
