@import "src/styles/common";
.content {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}
.topBar {
  display: flex;
  column-gap: 32px;
  align-items: center;
  margin-bottom: 52px;
}

.topHeading {
  flex-grow: 1;
}

.searchInput {
  max-width: 350px;
}

.selectChannelsType {
  width: 230px;
}
.pagination {
  display: flex;
  align-items: center;
  column-gap: 32px;
  flex-grow: 1;
  justify-content: flex-end;
}
.paginationTop {
  margin-bottom: 16px;
}
.paginationBottom {
  margin-top: 16px;
}

.topPagination {
  display: flex;
  margin-bottom: 16px;
  column-gap: 16px;
  align-items: center;
}

.bottomPagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 16px;
}