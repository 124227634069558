@import "../../styles";

.btn {
  display: flex;
  flex-shrink: 0;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  background: $background;
  border: none;
  border: 1px solid $border;
  border-radius: 4px;
  transition: background 0.4s ease;
  color: $gray;
  padding: 0;

  &:hover {
    background: $white;
  }

  &:active {
    background: $background;
  }
}
