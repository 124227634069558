@import "./breaks";
@import "./fonts.scss";

$blue: #41a8ed;
$blue-menu-bg: #158fe0;
$blue-hover: #0a7ac3;
$blue-active: #1383cd;
$blue-icon-default: #a1d3f7;
$blue-bg: #f5fbff;
$background: #f2f7fa;
$black: #1b2a47;
$gray: #6e7e9d;
$gray-light: #d4d7e0;
$border: #d3e4ee;
$border-hover: #b6d6e9;
$white: #ffffff;
$red: #fe5f55;
$red-hover: #cb0e01;
$red-active: #df1001;
$green: #47c281;
$green-hover: #2e8a59;
$green-active: #339963;
$yellow: #f0b90b;
$orange: #dc6b41;
$red-bg: #ffe6e4;
$yellow-bg: #fff5d6;
$green-bg: #def5e9;

$transparent: transparent;
$transparentHover: transparent;
$sm: 767.9px;

$md: 768px;
$lg: 1440px;
$xl: 1920px;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f4f6fd;
  font-family: "Source Sans Pro", sans-serif;
}

html {
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@mixin placeholder() {
  ::-webkit-input-placeholder {
    color: $black;
  }

  :-moz-placeholder {
    color: $black;
  }

  ::-moz-placeholder {
    color: $black;
  }

  :-ms-input-placeholder {
    color: $black;
  }

  ::-ms-input-placeholder {
    color: $black;
  }

  ::placeholder {
    color: $black;
  }
}

.page {
  display: flex;
  flex-direction: column;
  padding: 32px;
  overflow-y: auto;
  max-height: 100vh;
  height: 100%;
  box-sizing: border-box;
}
