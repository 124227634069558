@import "../../styles";

.container {
  width: 100%;
  background: $white;
  border: 1px solid $border;
  border-radius: 4px;
  overflow: hidden;
  height: 148px;
  position: relative;
}
.bottomLine {
  height: 10px;
  width: 100%;
  border-radius: 0 0 3px 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.inner {
  padding: 16px 20px;
}

.titleCardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 37px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: $black;
  margin-right: 30px;
}

.arrow {
  position: relative;
  top: 4px;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number {
  display: flex;
  align-items: flex-start;
}

.numberValue {
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  color: $black;
  margin-right: 8px;
}

.numberPostfix {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: $black;
}
