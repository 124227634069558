@import "src/styles/common";

.logo {
  margin-top: 75px;
}
.loginContainer {
  display: flex;
  height: 100vh;
  background-color: $blue;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footer {
  font-weight: 400;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  color: $white;
  margin-bottom: 32px;
}
.field {
  margin-bottom: 24px;
}