@import "src/styles/common";

.navigationSideBar {
  background-color: $blue-menu-bg;
  height: 100vh;
  padding: 30px 16px;
  width: 248px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 12px;
}

.navigationList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.navigationListTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Outfit';
  color: $blue-icon-default;
  padding: 0 12px;
}

.navigationLink a {
  padding: 0 12px;
  color: $white;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  height: 40px;
  transition: background-color ease .3s;
  align-items: center;
}

.navigationLink button {
  padding: 0 12px;
  color: $white;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  height: 40px;
  transition: background-color ease .3s;
  align-items: center;
  border: none;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}
.navigationLink button:hover {
  background-color: $blue-active;
}

.activeLink {
  background-color: $blue-active;
}

.navigationLink a:hover {
  background-color: $blue-active;
}

.navigationIcon {
  margin-right: 26px;
}