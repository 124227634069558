@import "../../styles";

.container {
  display: flex;

  @media (max-width: 499.9px) {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
}

.flex {
  display: flex;
}

.button {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d3e4ee;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1b2a47;
  font-family: "Source Sans Pro", sans-serif;

  font-weight: 600;
  font-size: 14px;

  line-height: 30px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  // todo improve hover
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: $gray-light;
    cursor: default;
  }

  @media (max-width: 767.9px) {
    margin-right: 4px;
  }
}

.buttonPoints {
  border: 1px solid transparent;
  background-color: transparent;

  &:hover {
    cursor: auto;
  }

  @media (max-width: 499.9px) {
    display: none;
  }
}

.buttonActive {
  background: #f5fbff;
  color: #41a8ed;
}
