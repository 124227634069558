@import "../../styles";

.redDot {
  color: $red;
  font-weight: bold;
  margin-left: 8px;
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.labelText {
  color: $gray;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
}
.select {
  height: 48px;
}
