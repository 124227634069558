* {
  box-sizing: border-box;
}

.input {
  width: 100%;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  padding: 0 24px;
  color: $black;
  border: 1px solid $border;
  border-radius: 5px;
  transition: all 0.2s linear;
  background: $background;

  &:disabled {
    background: $blue-bg !important;
  }

  @media (max-width: 767.9px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.input:focus {
  background: $white;
}

.container {
  position: relative;
  margin-bottom: 32px;

  &::after {
    content: attr(data-tooltip-text);
    display: block;
    position: absolute;
    z-index: 3;
    top: 0;
    font-weight: normal;
    right: 5px;
    color: #199954;
    font-size: 12px;
  }

  @media (max-width: 767.9px) {
    margin-bottom: 16px;
  }
}

.containerRow {
  display: flex;
}

.inputError {
  border: 1px solid $red;
}

.inputSuccess {
}

.completedText {
  color: $green;
}

.inputDisabled {
  background: $background;
  color: $black;
  cursor: auto;

  &.disabledBright {
    color: #333;
    background: #f4f6fd !important;
  }
}

.inputCopyToClipboard {
  padding: 0 48px 0 24px;
}

.error {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

.errorText {
  color: $red;
  font-size: 12px;
  line-height: 24px;
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  margin-bottom: -2px;
}

.verifyText {
  color: $green;
  font-size: 12px;
  line-height: 24px;
  font-family: "Outfit", sans-serif;
  font-weight: normal;
}

.errIcon {
  margin-right: 8px;
  height: 24px;
}

.wrapperLabels {
  display: flex;
  justify-content: space-between;
}

.maxColor {
  color: $red;
  cursor: pointer;
}

.wrapperInput {
  position: relative;
}

.typeValue {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.iconButton {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: calc(50% + 2px);
  transform: translateY(-50%);
}
