.DayPicker {
  width: 100%;
  border-radius: 10px;
}
.DayPicker-Caption {
  text-align: center;
  font: 500 18px/32px Outfit;
  height: 32px;
  padding: 0;
}
.DayPicker-Month {
  width: 100%;
  margin: 20px;
  border-spacing: 5px 10px;
  border-collapse: separate;
}
.DayPicker-wrapper {
  padding-bottom: 0;
}
.DayPicker-Day {
  font-size: 18px;
  color: #1b2a47;
  font-family: "Source Sans Pro";
  border-radius: 5px !important;
  padding: 0;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.DayPicker-Day--today {
  color: #41a8ed;
  font-weight: 500;
}
.DayPicker-Months {
  flex-wrap: nowrap !important;
}
.DayPicker-NavButton {
  margin: 0;
  width: 24px;
  height: 24px;
  top: 24px;
}
.DayPicker-NavButton--prev {
  left: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDFMMSA3TDcgMTMiIHN0cm9rZT0iIzZFN0U5RCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}
.DayPicker-NavButton--next {
  right: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEgMTMiIHN0cm9rZT0iIzZFN0U5RCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}
.DayPickerInput-Overlay {
  box-shadow: 4px 4px 16px #eef0f4;
  border-radius: 10px;
}
.DayPicker-Weekdays {
  margin-top: 0;
}
.DayPicker-Weekday {
  padding: 0;
  font: 400 12px/20px Outfit;
  color: #6e7e9d;
}

.InputFromTo-to input,
.InputFromTo-from input {
  font-family: "Source Sans Pro", sans-serif;
  width: 180px;
  outline: none;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  background-color: #f2f7fa;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  padding: 0 24px;
  color: #1b2a47;
  border: 1px solid #d9e1ff;
  border-radius: 5px;
  transition: all 0.2s linear;
}
.labelInput {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1b2a47;
  margin-bottom: 8px;
}

.InputFromTo {
  display: flex;
}
.InputFromTo-from {
  margin-right: 16px;
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  color: #1b2a47;
  background: #f5fbff;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  background-color: #41a8ed !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.InputFromTo .DayPicker-Day--start:hover {
  background-color: #2e8a59 !important;
}

.InputFromTo .DayPicker-Day--end {
  background-color: #41a8ed !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.InputFromTo .DayPicker-Day--end:hover {
  background-color: #2e8a59 !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 346px;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: 0px;
}

@media (max-width: 767.9px) {
  .InputFromTo-to input,
  .InputFromTo-from input {
    width: 100%;
  }
}

.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)::before {
  content: "";
  height: 100%;
  width: 5px;
  left: -5px;
  top: 0;
  position: absolute;
  background: #f5fbff;
}
.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)::after {
  content: "";
  height: 100%;
  width: 5px;
  right: -5px;
  top: 0;
  position: absolute;
  background: #f5fbff;
}

.DayPicker-Day-noAfter::after {
  display: none;
}
.DayPicker-Day-noBefore::before {
  display: none;
}
