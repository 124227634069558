@import "../../styles";

.root {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.hint {
  font-size: 14px;
  line-height: 24px;
}

@mixin placeholder() {
  ::-webkit-input-placeholder {
    color: $black;
  }

  :-moz-placeholder {
    color: $black;
  }

  ::-moz-placeholder {
    color: $black;
  }

  :-ms-input-placeholder {
    color: $black;
  }

  ::-ms-input-placeholder {
    color: $black;
  }

  ::placeholder {
    color: $black;
  }
}

.input {
  width: 100%;
  outline: none;
  position: relative;
  color: #1b2a47;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  height: 32px;
  padding: 0 35px 0 16px;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid $border;
  border-radius: 3px;
  transition: all 0.2s linear;
  background: white;
  text-align: center;
  cursor: pointer;
  @include placeholder();
}

.listItemElement {
  padding: 0 10px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-family: "Source Sans Pro";
  max-width: 100%;
  overflow: hidden;
  color: $black;
  background: white;

  &:hover {
    background: #f5fbff;
    color: #41a8ed;
  }

  &:focus {
    outline: none !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.listItemElementActive {
  background: $white;
}

.listItemElementActive {
  background: $white;
}

.arrowIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  padding-right: 6px;

  svg {
    margin-top: 4px;
    padding-right: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}

.wrapper {
  display: flex;
  position: relative;
  width: 76px;
  cursor: pointer;
}

.list {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 30px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid $border;
  padding: 7px 0;
  border-radius: 3px;
}
