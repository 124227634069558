@import "../../styles";

.container {
  position: relative;
  padding: 7px 0;

  &:hover {
    cursor: pointer;
  }
}

.dropdown {
  width: 176px;
  box-sizing: border-box;
  flex-shrink: 0;
  overflow: hidden;
  background: white;
  border: 1px solid $border;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  right: -35px;
  top: 12px !important;
  height: fit-content;
}

.topPosition {
  top: 0;
  transform: translateY(-100%);
}

.bottomPosition {
  top: 100%;
}

.icon {
  margin-right: 16px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: "Source Sans Pro", sans-serif;
  color: $black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.item {
  display: flex;
  align-items: center;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    background-color: $blue-bg;
  }
}

.textAccent {
  color: $red;
}

.textBlue {
}

.section {
  padding: 7px 0;
}

.border {
  width: 100%;
  border-top: 1px solid $border;
  margin: 0;
}

.iconWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapperButton {
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding-left: 16px;
  width: auto;
}

.iconWrapperNoPadding {
  padding-left: 0;
  padding-right: 0;
}
