.form,
.formService {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.formRow {
  display: flex;
  column-gap: 16px;
}

.removeBtn {
  margin-top: 32px;
}
