@import "src/styles/common";

.userTariffsHistoryHead {
  margin-top: 66px;
}
.active {
  color: $green;
}
.notActive {
  color: $red-active;
}
.userTariffsTable {
  margin-top: 32px;
}