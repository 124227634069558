@import "../../styles";

$border-width: 2px;

// base styles
.pbButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  width: max-content;
  margin: 0;
  padding: 0;
  background: transparent;
  transition: background 0.3s ease;
  font-family: Source Sans Pro, serif;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  white-space: nowrap;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.pbButtonOutlined .loading svg {
  color: $black;
}

// colors
.primary {
  background: $blue;
  color: $white;

  &:hover {
    background: $blue-hover;
  }

  &:focus {
    outline: $blue-icon-default;
  }
}

.success {
  background: $green;
  color: $white;

  &:hover {
    background: $green-hover;
  }

  &:focus {
     outline: $green-bg;
  }
}

.danger {
  background: $red;
  color: $white;

  &:hover {
    background: $red-hover;
  }

  &:focus {
     outline: $red-bg;
  }
}

.secondary {
  background: $background;
  color: $gray;

  &:hover {
    background: $border-hover;
  }

  &:focus {
     outline: $blue-icon-default;
  }
}

.secondary-bordered {
  background: $background;
  color: $gray;
  box-shadow: 0 0 0 1px $border;

  &:hover {
    background: $border-hover;
  }

  &:focus {
     outline: $blue-icon-default;
  }
}

// sizes
.lg {
  font-size: 14px;
  padding: 12px 40px;
  height: 48px;
}

.md {
  font-size: 14px;
  padding: 8px 24px;
  height: 40px;
}

.sm {
  height: 24px;
  font-size: 12px;
  padding: 4px 12px;

  .icon svg {
    height: 10px;
    width: 10px;
  }
}

.icon {
  display: block;
  position: relative;
  line-height: 0;

  svg {
    width: 15px;
    height: 15px;
  }
}

.secondary .icon,
.secondary-bordered .icon {
  svg {
    color: $black;
  }
}
.fullwidth {
  width: 100%;
}
