.drawerWrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}
.header {
  padding: 40px;
  border-bottom: 1px solid #d3e4ee;
  @media (max-width: 767.9px) {
    padding: 24px 16px;
  }
}
.footer {
  border-top: 1px solid #d3e4ee;
  padding: 40px;
  @media (max-width: 767.9px) {
    padding: 16px;
  }
}
.content {
  padding: 40px;
  flex: 1;
  overflow-y: scroll;
  @media (max-width: 767.9px) {
    padding: 16px;
  }
}
.headerTitleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #1b2a47;
  font-family: "Outfit", sans-serif;
}
.headerClose {
  cursor: pointer;
}

.confirmFooter {
  display: flex;
  column-gap: 24px;
}

.confirmFooterBtn {
  width: 100%;
}
