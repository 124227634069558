@import "src/styles/common";
.content {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}
.topBar {
  display: flex;
  column-gap: 32px;
  align-items: center;
  margin-bottom: 52px;
}

.topHeading {
  flex-grow: 1;
}

.searchInput {
  max-width: 350px;
}

.trial {
  color: $green-active;
}
.active {
  color: $blue;
}
.expired {
  color: $yellow;
}
.suspended,
.payment_required,
.unsubscribed,
.blocked,
.deleted {
  color: $red;
}

.pagination {
  display: flex;
  align-items: center;
  column-gap: 32px;
  flex-grow: 1;
  justify-content: flex-end;
}
.paginationTop {
  margin-bottom: 16px;
}
.paginationBottom {
  margin-top: 16px;
}

.topPagination {
  display: flex;
  margin-bottom: 16px;
  column-gap: 16px;
  align-items: center;
}

.bottomPagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 16px;
}

.switcherWrapper {
  display: flex;
  align-items: center;
  & > div > span {
    white-space: nowrap;
    margin-left: 16px;
    color: #1b2a47;
  }
  @media (max-width: 767.9px) {
    width: 100%;
  }
}
.switcher {
  transform: scale(3);
  margin-right: 16px;
  margin-left: 12px;
}

.additionSelect {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.filteredUsers {
  padding: 0 32px;
  span {
    font-weight: 600;
  }
}
.additionLabel {
  font-size: 14px;
}

.select {
  min-width: 200px;
}
.link {
  color: $blue;
  font-weight: 500;
}
.linkDisabled {
  color: $orange;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
}
.multiFilter {
  min-width: 250px;
}