.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 52px;
}

.main {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.pagination {
  display: flex;
  width: 100%;
  column-gap: 32px;
  align-items: center;
  justify-content: flex-end;
}
.content {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}