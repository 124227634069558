@import "../../styles";

.h1,
.h2,
.h3,
.h4 {
  font-family: "Outfit", serif;
  color: $black;
  font-weight: 500;
  margin: 0;
}

.h1 {
  font-size: 36px;
  line-height: 48px;
}
.h2 {
  font-size: 24px;
  line-height: 32px;
}
.h3 {
  font-size: 20px;
  line-height: 32px;
}
.h4 {
  font-size: 18px;
  line-height: 32px;
}
