@import "src/styles/common";
.content {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}
.topBar {
  display: flex;
  align-items: center;
  margin-bottom: 52px;
  justify-content: space-between;
}
.topBarActions {
  display: flex;
  column-gap: 32px;
  align-items: center;
}
.statisticsCard {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
}
.profileOverview {
  margin-top: 32px;
  background: $white;
  border: 1px solid $border;
  border-radius: 4px 4px 0 0;
}
.profileOverviewHead {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border;
}
.editProfile {
  cursor: pointer;
}
.tablesWrap {
  display: flex;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 900px) {
    display: block;
  }
}
.profileTable {
  width: 100%;
}
.profileOverviewTable {
  tr {
    height: 56px;
  }
  td {
    padding: 0 24px;
  }
}
.leftPartTableColor {
  color: $gray;
}
.trial {
  color: $green-active;
}
.active {
  color: $blue;
}
.expired {
  color: $yellow;
}
.deleted {
  color: $red-active;
}
.success {
  background: #def5e9;
  color: #3bb273;
}

.pending {
  background: #fff5d6;
  color: #f0b90b;
}

.failed {
  background: #ffe6e4;
  color: #fe5f55;
}
.tdAmount {
  display: flex;
  align-items: center;
  column-gap: 70px;
}
.pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: $blue;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.cardAssetWrapper {
  display: flex;
  align-items: center;
}
.cardAsset {
  height: 24px;
  margin-right: 16px;
}
.paymentMethod,
.billingHistory,
.accessKeysTitle {
  margin-top: 66px;
}
.paymentMethodTitle,
.accessKeysTitle {
  margin-bottom: 32px;
}
.billingHistoryTable,
.accessKeysTable {
  margin-top: 32px;
}
.billingHistoryHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statusText {
  border-radius: 4px;
  width: 65px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding: 4px 0;
}
.pagination {
  display: flex;
  align-items: center;
  column-gap: 32px;
  flex-grow: 1;
  justify-content: flex-end;
  margin-top: 22px;
}

.chartWrapper {
  position: relative;
  margin-top: 32px;
}

.chartStorageUsageSkeleton {
  height: 706px;
  width: 100%;
  border-radius: 4px;
}
