.modal {
  max-width: 450px;
}

.footer {
  display: flex;
  column-gap: 20px;
  width: 100%;
  margin: 5px;
}

.btn {
  width: 100%;
}
