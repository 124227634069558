@import "../../styles";

.wrapperGradient {
  background: linear-gradient(to bottom, $blue, $blue);
  border-radius: 50%;
  width: 375px;
  height: 375px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px;
  box-sizing: border-box;
  //@media (max-width: 1439.9px)  {
  //  width: 416px;
  //  height: 416px;
  //}
  @media (max-width: 767.9px) {
    width: 346px;
    height: 346px;
  }
  @media (max-width: 374.9px) {
    width: 290px;
    height: 290px;
  }
}
.wrapperGradient.noGradient {
  background: none;
  width: auto;
  height: auto;
}
.innerBlock {
  background: white;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  box-sizing: border-box;
}
.text {
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: $gray;
  margin-bottom: 32px;
}
.wrapButton {
  width: 140px;
  margin: 0 auto;
}
.icon {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  @media (max-width: 374.9px) {
    margin-bottom: 6px;
  }
}
.wrapper {
  margin: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 129px;
  padding-top: 32px;
}
