@import "src/styles/common";
.content {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 52px;
}

.main {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.controlContainer {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: end;
}
.rightActions {
  display: flex;
  gap: 20px;
}