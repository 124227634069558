@import "../../styles";
 
.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.labelText {
  color: $gray;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
}


.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.input {
  width: 100%;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  flex-grow: 1;
  padding: 12px  50px 12px 16px;
  background: $white;
  border-radius: 5px;
  border: 1px solid $border;

  &:focus-visible {
    outline: none;
  }
}

.textArea {
  min-height: 74px;
  word-break: break-word;
  white-space: nowrap;
}

.wrapperVisible{
  position: absolute;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
}

.inputError {
  border-color: $red;
}
.inputSign {
  color: $gray-light;
  margin-left: 10px;
}

.error {
  display: flex;
  color: $red;
  margin-top: 8px;
  align-items: center;
}
.errorText {
  margin-left: 11px;
}
.redDot {
  color: $red;
  font-weight: bold;
  margin-left: 8px;
}
