@import "../../styles";

.form {
  padding: 40px;
  background-color: $white;
  border-radius: 10px;
  width: 100%;
}
.title {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 24px;
}