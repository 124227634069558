@import "../../styles";

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 32px;
}

.tableTr {
  height: 56px;

  & > td {
    vertical-align: top;
  }
}
.mb32 {
  margin-bottom: 32px;
}
.cellTitle {
  width: 170px;
  color: $gray;
  margin-bottom: 32px;
}
.fileBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.downloadFile {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: $green;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.fileInfo {
  display: flex;
  align-items: center;
}
.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileInfoIcon {
  margin-right: 20px;
}