@import "../../styles";
@import "./input.module.scss";

.chartsContainer {
  background: white;
  border: 1px solid $border;
  border-radius: 4px;
}

.chartWrap {
  padding: 0 4px 20px;
  background: white;
}

.inputWrap {
  width: 180px;
  margin-right: 16px;

  & input {
    text-align: center;
  }

  @media (max-width: 1439.9px) {
    width: 170px;
  }
  @media (max-width: 767.9px) {
    width: 130px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 374.9px) {
    width: 110px;
  }
}

.inputChart {
  @media (max-width: 374.9px) {
    padding: 0 10px;
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
  padding: 24px;
  padding-bottom: 12px;
  width: 100%;

  @media (max-width: 1023.9px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 40px;
  }

  @media (max-width: 374.9px) {
    padding: 15px;
  }
}

.inputsRow {
  display: flex;
}

.chartButton {
  color: white;
  border-radius: 4px;
  width: 58px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  transition: background 0.2s;
  background-color: $green;
  cursor: pointer;
  padding-top: 3px;
  @media (max-width: 767.9px) {
    width: 50px;
  }
  @media (max-width: 374.9px) {
    flex: 1 0;
  }
}
.chartButtonActive {
  background-color: $green-active;
}

.buttons {
  display: flex;
  padding-top: 32px;
  gap: 8px;
  @media (max-width: 767.9px) {
    gap: 10px;
  }

  @media (max-width: 374.9px) {
    width: 100%;
  }

  & .chartButton:last-child {
    margin-right: 0;
  }
}

.chartButton {
  cursor: pointer;
}

.chartButton:hover {
  background: $green-hover;
}

.chartStorageContainer {
  width: 100%;
  height: 500px;
  min-width: 100%;
}

.tab {
  text-transform: capitalize;
}

.block {
  background: white;
  border: 1px solid $border;
  border-radius: 4px;
}

.blockHeader {
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid $border;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767.9px) {
    padding: 16px 24px;
    height: auto;
  }
}

.blockTitle {
  color: $black;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.headerBlockTabsTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 374.9px) {
    padding: 0 15px;
  }
}

.headerBlockTabs {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: $black;
  @media (max-width: 767.9px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.tabs {
  display: flex;

  & .tab:last-child {
    margin-right: 0;
  }
}

.tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  width: 140px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -2.5px;
  border-bottom: 3px solid transparent;
  margin-right: 5px;
  color: $gray;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767.9px) {
    width: 80px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
}

.tab.tabActive {
  color: $blue;
  border-bottom: 3px solid $blue;
}
