@import "src/styles/common";
.container {
  padding: 32px;
}
.content {
  flex: 1;
  overflow-y: auto;
  height: 100vh;
}
.head {
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
}
.headTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $black;
}
.statisticsCard {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
}