.actions {
  display: flex;
  gap: 24px;
}
.check {
  display: inline-block;
  margin-bottom: 32px;
}
button.action {
  width: 50%;
}
.grid2fr {
  display: flex;
  gap: 16px;
}
.field {
  margin-bottom: 24px;
}
.select {
  width: 100%;
}